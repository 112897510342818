import(/* webpackMode: "eager", webpackExports: ["Container","Grid","Chip","Typography","Box"] */ "__barrel_optimize__?names=Box,Chip,Container,Grid,Typography!=!/usr/src/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Grid","Chip","Typography","Container","Skeleton"] */ "__barrel_optimize__?names=Chip,Container,Grid,Skeleton,Typography!=!/usr/src/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Skeleton"] */ "__barrel_optimize__?names=Skeleton!=!/usr/src/node_modules/@mui/material/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/material/Chip/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/styled-engine/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/system/esm/Box/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/system/esm/Container/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/system/esm/createBox.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/system/esm/cssVars/useCurrentColorScheme.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/system/esm/GlobalStyles/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/system/esm/Stack/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/system/esm/Stack/Stack.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/system/esm/ThemeProvider/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/system/esm/Unstable_Grid/Grid.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/system/esm/Unstable_Grid/index.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/system/esm/useMediaQuery/useMediaQuery.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/system/esm/useTheme.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/system/esm/useThemeProps/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/system/esm/useThemeWithoutDefault.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/utils/useControlled/useControlled.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/utils/useEnhancedEffect/useEnhancedEffect.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/utils/useEventCallback/useEventCallback.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/utils/useForkRef/useForkRef.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/utils/useId/useId.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/utils/useIsFocusVisible/useIsFocusVisible.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/utils/useLazyRef/useLazyRef.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/utils/useOnMount/useOnMount.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/node_modules/@mui/utils/usePreviousProps/usePreviousProps.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/@mui/utils/useTimeout/useTimeout.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/usr/src/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/usr/src/src/app/(home)/components/RecommendedCards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/usr/src/src/shared/components/Icon/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/src/shared/components/ResourcesListComponent/index.tsx");
;
import(/* webpackMode: "eager" */ "/usr/src/src/shared/components/SearchBar/index.tsx");
