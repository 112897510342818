'use client';
import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { useMediaQuery } from '@mui/material';
import ResourceCard from '@/shared/components/ResourceCard';
import theme from '@/lib/theme/theme';
import EmblaCarousel from '@/shared/components/Carousel';

import ResourcesDataApi from '@/shared/apis/resources/resources.api';
import ResourceInterface from '@/shared/apis/resources/types.resources';

export default function RecommendedCards() {
  const [recommendedResources, setRecommendedResources] = useState<
    ResourceInterface[]
  >([]);
  const isMobileScreen: boolean = useMediaQuery<boolean>(
    theme.breakpoints.down('sm'),
  );

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await ResourcesDataApi.FetchResources({
        isRecommended: true,
      });
      setRecommendedResources(data);
    };
    fetchData();
  }, []);

  if (!recommendedResources.length) {
    return (
      <Grid container spacing={'20px'}>
        {[1, 2, 3, 4].map(item => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={item}>
            <ResourceCard
              resource={{
                id: 1,
                name: 'Resource name',
                company: {
                  name: 'Company name',
                  categories: [],
                  id: item,
                  image_path: '',
                },
                keywords: ['keyword1', 'keyword2'],
                type: 'Resource type',
                image_path: '/resource-empty-picture.svg',
                file_path: '',
                resource_type: '',
                resource_type_id: item,
                short_description: '',
                resource_link: '',
                source_main_img_url: '',
                referencing_links: '',
                resource_creation_date: '',
                created_datetime: '',
                doc_language: '',
                downloads_count: 0,
                num_of_downloads: 0,
                num_of_pages: 0,
                reading_time: 0,
                is_day: '',
                is_top: '',
                is_trend: '',
                is_recommended: '',
                resource_update_date: '',
              }}
              loading
              disbleRowResponsive
            />
          </Grid>
        ))}
      </Grid>
    );
  }

  return isMobileScreen ? (
    <EmblaCarousel cards={recommendedResources} />
  ) : (
    <Grid container spacing={'20px'}>
      {recommendedResources.map((item, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <ResourceCard
            resource={{
              ...item,
              resource_creation_date: '',
            }}
            disbleRowResponsive
          />
        </Grid>
      ))}
    </Grid>
  );
}
